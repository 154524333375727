module.exports = {
  brand: 'bcom',
  storePickupInfoLink: 'https://customerservice-bloomingdales.com/articles/can-i-place-an-order-online-and-pick-it-up-in-a-bloomingdales-store',
  sddDetailsLink: 'https://customerservice-bloomingdales.com/articles/same-day-chart',
  sddInfoLink: 'https://customerservice-bloomingdales.com/articles/what-is-same-day-delivery',
  pickItUp: 'Pick it up your way',
  learnMoreSdd: 'Learn more about ',
  learnMorePickup: 'Learn more about Store Pickup',
  stillQuestion: 'Still have questions?',
  noMatchesFound: '0 matches found',
};
